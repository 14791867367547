import './style.css'
import * as React from 'react'

function UiLogo() {
  return (
    <div className="ui-logo">
      Care.tv
    </div>
  )
}

export default UiLogo